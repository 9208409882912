import {GeojsonLoader} from '../data/edk/geojson-loader';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class GeojsonLoaderService extends GeojsonLoader {
    constructor(private http: HttpClient) {
        super();
    }
    load(assetName: string): Observable<any> {
        return this.http.get(assetName);
    }

}